import * as Sentry from '@sentry/nextjs'

import {
  path,
  shopifyCartCookie,
  shopifyClient,
  VITALIVE_SERVER_URL,
} from '../constants/constants'
import { setCookies, getCookie } from 'cookies-next'
import { getObjectFromCookie } from './getObjectFromCookie'
import { CookieSetOptions } from 'universal-cookie'
import { parseJson } from './jsonUtils'
import axios from 'axios'

export const createCheckout = async (
  customerEmail: string = '',
  passedCookieSetter:
    | ((
        name: 'shopifyCart',
        value: any,
        options?: CookieSetOptions | undefined
      ) => void)
    | null = null
) => {
  const _params: {
    email?: string
    lineItems?: { variantId: string; quantity: number }[]
  } = {}

  let countOfProducts = 0
  let shopifyCookie = getCookie(shopifyCartCookie)
  const _shopifyCookie = getObjectFromCookie(shopifyCookie as string)
  const newCartContent = {}

  if (customerEmail) {
    _params.email = customerEmail
  }
  if (_shopifyCookie && _shopifyCookie.checkoutLineItems) {
    const _lineItems: { variantId: string; quantity: number }[] = []

    Object.keys(_shopifyCookie?.checkoutLineItems)?.forEach((key) => {
      if (
        _shopifyCookie.checkoutLineItems[key] &&
        _shopifyCookie.checkoutLineItems[key]?.isMembership
          ? +_shopifyCookie.checkoutLineItems[key].itemPrice > 0
          : _shopifyCookie.checkoutLineItems[key] &&
            !_shopifyCookie.checkoutLineItems[key]?.isMembership
      ) {
        _lineItems.push({
          variantId: key,
          quantity: _shopifyCookie.checkoutLineItems[key]?.quantity,
        })
        countOfProducts += _shopifyCookie.checkoutLineItems[key]?.quantity
        newCartContent[key] = { ..._shopifyCookie.checkoutLineItems[key] }
      }
    })

    _params.lineItems = _lineItems
  }

  try {
    let res
    if (Object.keys(_params).length) {
      res = await shopifyClient.checkout.create(_params as string)
    } else {
      res = await shopifyClient.checkout.create()
    }

    res = parseJson<any>(JSON.stringify(res))

    if (passedCookieSetter) {
      passedCookieSetter(
        shopifyCartCookie,
        {
          checkoutId: res.id,
          countOfProducts: countOfProducts ?? 0,
          checkoutLineItems: newCartContent,
        },
        path
      )
    } else {
      setCookies(
        shopifyCartCookie,
        {
          checkoutId: res.id,
          countOfProducts: countOfProducts ?? 0,
          checkoutLineItems: newCartContent,
        },
        path
      )
    }
    return res.id
  } catch (err) {
    Sentry.captureException(err)
    console.error('createCheckout: ', err)
  }
}

export const getProductBySky = async (productsSku: string) => {
  try {
    const res = await axios.get(
      `${VITALIVE_SERVER_URL || ''}/product/sku/${productsSku}`
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    console.log('getProductBySky', err)
  }
}

export const checkDiscountCode = async (discountCode: string) => {
  try {
    const res = await axios.get(
      `${VITALIVE_SERVER_URL || ''}/discountcode/${discountCode}`
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    console.log('getProductBySky', err)
  }
}

// export const updateCheckoutEmail = async (customerEmail: string) => {
//   try {
//     await shopifyClient.checkout.updateEmail(
//       cookie.shopifyCart?.checkoutId,
//       customerEmail
//     )
//   } catch (err) {
//     Sentry.captureException(err)
//     console.error('createCheckout: ', err)
//   }
// }
