import * as Sentry from '@sentry/nextjs'
import {
  customerIdentityCookieName,
  VITALIVE_SERVER_URL,
} from '../constants/constants'
import axios from 'axios'
import { ProductDetails } from '../interfaces/CartContent'
import { DraftOrderLineItem, LineItem } from '../interfaces/ShopifyInterface'
import { getCookie } from 'cookies-next'
import { getObjectFromCookie } from './getObjectFromCookie'
import { refreshToken as refreshTokenFunc } from './refreshTokens'

export const getProductsWithNewPrice = async (
  products: ProductDetails | ProductDetails[],
  coefficientDiscountPrice?: number
) => {
  if (products instanceof Array) {
    return products.map((item) => ({
      ...item,
      price: (+item?.price * (coefficientDiscountPrice || 1)).toFixed(2),
    }))
  }

  return {
    ...products,
    price: (+products?.price * (coefficientDiscountPrice || 1)).toFixed(2),
  }
}

export const getDiscountPrice: any = async (ctx: any | undefined = null) => {
  const _identity = getCookie(customerIdentityCookieName, ctx ? ctx : {}) as any

  const accessToken = _identity
    ? getObjectFromCookie(_identity as string).accessToken
    : _identity

  if (!_identity) return 1

  try {
    const getDiscount = await axios
      .get(`${VITALIVE_SERVER_URL || ''}/product/price_coefficient`, {
        headers: {
          Authorization: `Bearer ${accessToken || ''}`,
        },
      })
      .then((res) => res.data)

    return getDiscount?.coefficient || 1
  } catch (err) {
    Sentry.captureException(err)
    const _identity = getCookie(customerIdentityCookieName, ctx ? ctx : {})
    const _refreshToken = _identity
      ? getObjectFromCookie(_identity as string).refreshToken
      : _identity

    if (err?.response?.status === 401) {
      if (_refreshToken)
        return refreshTokenFunc(() => getDiscountPrice(ctx), _refreshToken, ctx)
      return 1
    }
    return 1
  }
}

export const getLineItemsWithNewPrice = async (
  lineItems: LineItem | LineItem[],
  coefficientDiscountPrice: number | null = null,
  ctx: any | undefined = null
) => {
  let coefficientDiscount: number =
    coefficientDiscountPrice !== null
      ? coefficientDiscountPrice
      : await getDiscountPrice(ctx)

  if (coefficientDiscount === 1) {
    return lineItems
  } else {
    let newPrice: string | null = null

    if (lineItems instanceof Array) {
      return lineItems.map((item: LineItem) => {
        if (item.variant && item.variant.price) {
          newPrice = (+item?.variant?.price * coefficientDiscount).toFixed(2)
        }

        if (newPrice !== null) {
          return {
            ...item,
            variant: {
              ...item.variant,
              price: newPrice,
            },
          }
        }
        return item
      })
    }

    if (lineItems.variant && lineItems.variant.price) {
      newPrice = (+lineItems?.variant?.price * coefficientDiscount).toFixed(2)
    }

    if (newPrice !== null) {
      return {
        ...lineItems,
        variant: {
          ...lineItems.variant,
          price: newPrice,
        },
      }
    }

    return lineItems
  }
}

export const getDraftOrderItemsWithNewPrice = async (
  lineItems: DraftOrderLineItem[],
  coefficientDiscountPrice: number | null = null,
  ctx: any | undefined = null
) => {
  let coefficientDiscount: number =
    coefficientDiscountPrice !== null
      ? coefficientDiscountPrice
      : await getDiscountPrice(ctx)

  if (coefficientDiscount === 1) {
    return lineItems
  } else {
    let newPrice: string | null = null

    return lineItems.map((item: DraftOrderLineItem) => {
      if (item.price) {
        newPrice = (+item?.price * coefficientDiscount).toFixed(2)
      }

      if (newPrice !== null) {
        return {
          ...item,
          price: newPrice,
        }
      }
      return item
    })
  }
}

export const newPrice = (item: any) => {
  return (
    +(item?.price || 0) * (item?.quantity || 1) -
    +(item?.total_discount || 0)
  ).toFixed(2)
}

export const chooseGetterOfNewPrice = async (
  type: string,
  data: {
    products: any[]
    coefficientDiscountPrice: number | null
    ctx: any | undefined
  }
) => {
  switch (type) {
    case 'LineItems': {
      return await getLineItemsWithNewPrice(
        data.products,
        data.coefficientDiscountPrice,
        data.ctx
      )
    }
    case 'draftOrder': {
      return await getDraftOrderItemsWithNewPrice(
        data.products,
        data.coefficientDiscountPrice,
        data.ctx
      )
    }
    case 'products': {
      return await getProductsWithNewPrice(
        data.products,
        data.coefficientDiscountPrice
          ? data.coefficientDiscountPrice
          : undefined
      )
    }
  }
}
