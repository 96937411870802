import axios from 'axios'
import { setCookies, getCookie, removeCookies } from 'cookies-next'
import Router from 'next/router'
import * as Sentry from '@sentry/nextjs'
import {
  customerIdentityCookieName,
  path,
  VITALIVE_SERVER_URL,
} from '../constants/constants'
import { getObjectFromCookie } from './getObjectFromCookie'

export const refreshToken = async (
  callBackFunc: () => any,
  passedToken: string = '',
  ctx: any = null
) => {
  const router = Router.router
  const _identity = getCookie(customerIdentityCookieName)
  const refreshTokenFromCookie = _identity
    ? getObjectFromCookie(_identity as string).refreshToken
    : _identity

  let _refreshToken

  if (passedToken) {
    _refreshToken = passedToken
  } else {
    if (refreshTokenFromCookie) {
      _refreshToken = refreshTokenFromCookie
    } else {
      router?.push('/account/signin')
    }
  }

  try {
    const res = await axios.post(`${VITALIVE_SERVER_URL}/auth/tokenrefresh`, {
      refreshToken: _refreshToken,
      clientType: '1',
    })
    setCookies(
      customerIdentityCookieName,
      res.data,
      ctx
        ? {
            ...ctx,
            ...path,
          }
        : path
    )

    callBackFunc()
  } catch (err: any) {
    Sentry.captureException(err)
    console.log('refreshToken: ', err)
    if (!passedToken) {
      removeCookies(customerIdentityCookieName, path)
      router?.push('/account/signin')
    } else {
      removeCookies(customerIdentityCookieName, { ...ctx, ...path })
      return 'not_found'
    }
  }
}
